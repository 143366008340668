import React from "react";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Button,
  TextInput,
  Dropdown,
  Switch,
  RenderIf,
  Checkbox,
} from "../../../shared/ui-components";
import { GenerateUUID } from "../../../shared/Utility";
import { ClientComponent } from "../../../shared/constants/componentConstants";

const NumberTextInput = (props) => <TextInput {...props} type="number" />;

const AddEditClients = ({
  isAddForm,
  onAddSubmit,
  onEditSubmit,
  onCancel,
  selectedClient,
  shouldUseClientCredentialFlowOnly,
  apiScopesDropdownData
}) => {
  const validationSchema = Yup.object({
    clientId: Yup.string().required("Client Id is required."),
    clientName: Yup.string().required("Client Name is required."),
    allowedScopes: Yup.array()
      .nullable()
      .required("Please select atleast one scope."),
  });

  const isClientCredentialGrantTypeSelected = (grantType) =>
    ClientComponent.GrantTypes[0].value === grantType;
  const isSlidingExpirationTypeIsSlidingSelected = (slidingExpirationType) =>
    ClientComponent.RefreshTokenExpirationTypes[0].value ===
    slidingExpirationType.toString();

  const getClientDataForClientCredFlow = (formValues) => {
    return {
      id: formValues.id,
      clientName: formValues.clientName,
      clientId: formValues.clientId,
      allowedScopes: formValues.allowedScopes,
      refreshTokenExpirationType: formValues.refreshTokenExpirationType,
      absoluteRefreshTokenLifetime: formValues.absoluteRefreshTokenLifetime,
      slidingRefreshTokenLifetime: formValues.slidingRefreshTokenLifetime,
      accessTokenLifetime: formValues.accessTokenLifetime,
      isClientSecretRequired: formValues.isClientSecretRequired,
      clientSecret: formValues.clientSecret,
      grantType: formValues.grantType,
      includeStandardScopes: formValues.includeStandardScopes,
    };
  };

  return (
    <Formik
      initialValues={{
        id: isAddForm ? 0 : selectedClient.id,
        clientName: isAddForm ? "" : selectedClient.clientName,
        clientId: isAddForm ? "" : selectedClient.clientId,
        clientSecret: GenerateUUID(),
        grantType: isAddForm
          ? ClientComponent.GrantTypes[0].value
          : selectedClient.grantType,
        allowedScopes: isAddForm ? null : selectedClient.allowedScopes,
        redirectUrls: isAddForm ? "" : selectedClient.redirectUrls,
        postLogoutRedirectUrls: isAddForm
          ? ""
          : selectedClient.postLogoutRedirectUrls,
        allowedCorsOrigins: isAddForm ? "" : selectedClient.allowedCorsOrigins,

        refreshTokenExpirationType: isAddForm
          ? ClientComponent.RefreshTokenExpirationTypes[0].value
          : selectedClient.refreshTokenExpirationType,

        absoluteRefreshTokenLifetime: isAddForm
          ? 2592000
          : selectedClient.absoluteRefreshTokenLifetime,
        slidingRefreshTokenLifetime: isAddForm
          ? 1296000
          : selectedClient.slidingRefreshTokenLifetime,
        accessTokenLifetime: isAddForm
          ? 3600
          : selectedClient.accessTokenLifetime,
        isClientSecretRequired: isAddForm
          ? true
          : selectedClient.isClientSecretRequired,
        isConsentRequired: isAddForm ? false : selectedClient.isConsentRequired,
        isAllowedAccessTokenViaBrowser: isAddForm
          ? false
          : selectedClient.isAllowedAccessTokenViaBrowser,
        isPkceRequired: isAddForm ? false : selectedClient.isPkceRequired,
        isAlwaysSendClientClaims: isAddForm
          ? false
          : selectedClient.isAlwaysSendClientClaims,
        isAllowedOfflineAccess: isAddForm
          ? false
          : selectedClient.isAllowedOfflineAccess,
        includeStandardScopes: isAddForm
          ? false
          : selectedClient.includeStandardScopes,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values) => {
        values.refreshTokenExpirationType = parseInt(
          values.refreshTokenExpirationType
        );
        debugger

        if (isClientCredentialGrantTypeSelected(values.grantType)) {
          values = getClientDataForClientCredFlow(values);
        }

        if (isAddForm) {
          await onAddSubmit(values);
          return;
        }
        await onEditSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => {
        console.log(values);

        return (
          <Form
            className=""
            style={{ width: "100%", height: "100%", userSelect: "none" }}
          >
            <div
              className=""
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="">
                <h2 class="tab-title mb-32">
                  {isAddForm ? "Create Client" : "Edit Client"}
                </h2>
              </div>

              <div style={{ flex: 1, overflowX: "hidden", overflowY: "auto" }}>
                <div className="row">
                  <div className="col-6 pl-20">
                  <Field
                      component={TextInput}
                      name="clientName"
                      label="Client Name"
                    />
                    <Field
                      component={TextInput}
                      name="clientId"
                      label="Client Id"
                    />
                    <RenderIf shouldRender={!shouldUseClientCredentialFlowOnly}>
                      <Field
                        component={Dropdown}
                        name="grantType"
                        label="Grant Type"
                        options={ClientComponent.GrantTypes}
                      />
                    </RenderIf>

                    <Field
                      isMultiSelect={true}
                      component={Dropdown}
                      name="allowedScopes"
                      label="Allowed Scopes"
                      options={apiScopesDropdownData}
                    />

                    <RenderIf
                      shouldRender={
                        !isClientCredentialGrantTypeSelected(values.grantType)
                      }
                    >
                      <Field
                        component={TextInput}
                        name="redirectUrls"
                        label="Redirect Urls"
                      />

                      <Field
                        component={TextInput}
                        name="postLogoutRedirectUrls"
                        label="Post Logout Redirect Urls"
                      />

                      <Field
                        component={TextInput}
                        name="allowedCorsOrigins"
                        label="Allowed Cors Origins"
                      />
                    </RenderIf>

                    <Field
                      component={Dropdown}
                      name="refreshTokenExpirationType"
                      label="Refresh Token ExpirationType"
                      options={ClientComponent.RefreshTokenExpirationTypes}
                    />

                    <RenderIf
                      shouldRender={isSlidingExpirationTypeIsSlidingSelected(
                        values.refreshTokenExpirationType
                      )}
                    >
                      <Field
                        component={NumberTextInput}
                        name="slidingRefreshTokenLifetime"
                        label="Sliding Refresh Token Lifetime (in seconds)"
                      />
                    </RenderIf>

                    <RenderIf
                      shouldRender={
                        values.refreshTokenExpirationType ==
                        ClientComponent.RefreshTokenExpirationTypes[1].value
                      }
                    >
                      <Field
                        component={NumberTextInput}
                        name="absoluteRefreshTokenLifetime"
                        label="Absolute Refresh Token Lifetime (in seconds)"
                      />
                    </RenderIf>

                    <Field
                      component={NumberTextInput}
                      name="accessTokenLifetime"
                      label="Access Token Lifetimes (in seconds)"
                    />

                    <RenderIf shouldRender={isAddForm}>
                    <Field
                      component={TextInput}
                      name="clientSecret"
                      label="Client Secret"
                      disabled={true}
                    />
                    </RenderIf>

                    <Field
                      component={Checkbox}
                      name="isClientSecretRequired"
                      label="Require Client Secret"
                    />

                    <RenderIf
                      shouldRender={
                        !isClientCredentialGrantTypeSelected(values.grantType)
                      }
                    >
                      <RenderIf
                        shouldRender={values.isClientSecretRequired}
                      ></RenderIf>

                      <Field
                        component={Checkbox}
                        name="isConsentRequired"
                        label="Consent Required"
                      />

                      <Field
                        component={Checkbox}
                        name="isAllowedAccessTokenViaBrowser"
                        label="Allow Access Token via Browser"
                      />

                      <Field
                        component={Checkbox}
                        name="isPkceRequired"
                        label="PKCE Required"
                      />

                      <Field
                        component={Checkbox}
                        name="isAlwaysSendClientClaims"
                        label="Always Send Client Claims"
                      />

                      <Field
                        component={Checkbox}
                        name="isAllowedOfflineAccess"
                        label="Allow Offline Access"
                      />

                      <Field
                        component={Checkbox}
                        name="includeStandardScopes"
                        label="Include Standard Scopes"
                      />

                    </RenderIf>
                    <div className="content-align-right">
                      <Button
                        type="submit"
                        text={isAddForm ? "Add" : "Update"}
                      />
                      <Button
                        type="button"
                        styleType="secondary ml-10"
                        text="Cancel"
                        onClick={onCancel}
                      />
                    </div>
                  </div>

                  <div className="col-6" />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditClients;
